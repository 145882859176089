import Head from 'next/head'
import classNames from 'classnames'
import Header from './Header'
import BottomLeftUnits from './BottomLeftUnits'
import Footer from './Footer'

const PageTemplate = ({
	children,
	isFullWidth = false,
	isFullHeight = false,
}: {
	children: React.ReactNode
	isFullWidth?: boolean
	isFullHeight?: boolean
}) => {
	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="initial-scale=1, width=device-width, user-scalable=yes"
				/>
			</Head>
			<div
				className={classNames({
					container: true,
					'fixed-100-percent-height': isFullHeight,
				})}
			>
				<Header />
				{isFullWidth && <>{children}</>}
				{!isFullWidth && (
					<>
						<div id="page-column">{children}</div>
					</>
				)}
				<Footer />
			</div>
			<BottomLeftUnits />
		</>
	)
}

export default PageTemplate
