import React from 'react'
import * as Redux from 'react-redux'
import * as MantineCore from '@mantine/core'
import * as MantineHooks from '@mantine/hooks'

import * as Selectors from '../redux/selectors'
import { setMetric } from '../redux/store'
import RadioButtonGroup from './RadioButtonGroup'

const useUnit = () => {
	const dispatch = Redux.useDispatch()

	const setUnit = (unit: string | undefined): void => {
		if (!!unit) {
			dispatch(setMetric(unit))
		}
	}

	return { setUnit }
}

const BottomLeftUnits: React.FC = () => {
	const [opened, { open, close }] = MantineHooks.useDisclosure(false)
	const { setUnit } = useUnit()

	const unit = Redux.useSelector(Selectors.unitSelector)

	const unitOptions = [
		{ display: 'km + metres', value: 'metric' },
		{ display: 'miles + feet', value: 'imperial' },
	]

	return (
		<>
			<MantineCore.Modal
				opened={opened}
				onClose={close}
				title="Units"
				size="md"
			>
				<p>
					Set the units you want to use and see displayed throughout the site.
				</p>
				<h4>Distances + heights</h4>
				<RadioButtonGroup<string>
					items={unitOptions}
					value={unit}
					onChange={(val) => setUnit(val)}
					nullable={false}
					size="sm"
				/>
			</MantineCore.Modal>

			<div className="bottom-corner-element left-corner">
				<MantineCore.Button.Group>
					<MantineCore.Button
						onClick={() => open()}
						variant="default"
						radius={0}
					>
						<span
							style={{
								fontWeight: unit === 'metric' ? 'bold' : 'normal',
							}}
						>
							km
						</span>
						&nbsp;&nbsp;/&nbsp;&nbsp;
						<span
							style={{
								fontWeight: unit === 'imperial' ? 'bold' : 'normal',
							}}
						>
							miles
						</span>
					</MantineCore.Button>
				</MantineCore.Button.Group>
			</div>
		</>
	)
}

export default BottomLeftUnits
