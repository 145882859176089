// import * as MantineCore from '@mantine/core'
// import Link from 'next/link'

import * as Types from '../declarations'

export const getEmailAddress = (version: Types.VERSION) =>
	version === Types.VERSION.TREKKING
		? 'ay0qyvtp@tuncua.mailer.me'
		: '9uo5lrqc@tuncua.mailer.me'

const EmailAddress = ({ version }: { version: Types.VERSION }) => {
	const emailAddress =
		version === Types.VERSION.TREKKING ? (
			<>ay0qyvtp@tuncua.mailer.me</>
		) : (
			<>9uo5lrqc@tuncua.mailer.me</>
		)

	return emailAddress
}

export default EmailAddress
