import * as React from 'react'
// import * as ReactRedux from 'react-redux'
import * as MantineForm from '@mantine/form'
import * as ApolloClient from '@apollo/client'
import * as MantineCore from '@mantine/core'
import * as Icons from 'tabler-icons-react'

import * as GQLQueries from '../lib/queries'

const ResetPasswordForm = () => {
	const [errorDisplay, setErrorDisplay] = React.useState<string>('')
	const [successDisplay, setSuccessDisplay] = React.useState<string>('')

	const form = MantineForm.useForm({
		initialValues: {
			email: '',
		},
		validate: {
			email: (value) =>
				!value
					? 'Email is required'
					: !/^\S+@\S+$/.test(value)
					? 'Invalid email'
					: null,
		},
		validateInputOnChange: true,
	})

	const [
		resetUserPassword,
		{ error, loading: isLoading },
	] = ApolloClient.useMutation(GQLQueries.Mutations.ResetUserPassword)

	const handleSubmit = async (values: { email: string }) => {
		setErrorDisplay('')
		setSuccessDisplay('')
		const response = await resetUserPassword({ variables: values })

		const success = response?.data?.resetUserPassword

		if (success) {
			setSuccessDisplay(
				'If we have an account matching that email, you will receive a reset link shortly.',
			)
		} else {
			setErrorDisplay("request wasn't successful")
		}
	}

	const displayError = error?.message || errorDisplay || undefined

	return (
		<form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
			<MantineCore.Flex
				gap="md"
				justify="flex-start"
				direction="column"
				pt="md"
			>
				<MantineCore.TextInput
					label="Email"
					type="email"
					{...form.getInputProps('email')}
					placeholder={'the email address you signed up with'}
				/>
				<MantineCore.Button
					type="submit"
					mt="lg"
					disabled={isLoading || !form.isValid()}
				>
					{isLoading ? (
						<>
							Requesting reset link&nbsp;&nbsp;&nbsp;
							<MantineCore.Loader size="sm" variant="dots" color="gray" />
						</>
					) : (
						'Request reset link'
					)}
				</MantineCore.Button>
				{displayError && (
					<MantineCore.Alert
						icon={<Icons.AlertCircle size="1rem" />}
						title="Something went wrong"
						color="red"
					>
						{displayError}
					</MantineCore.Alert>
				)}

				{successDisplay && (
					<MantineCore.Alert
						icon={<Icons.AlertCircle size="1rem" />}
						title="Request received"
						color="green"
					>
						{successDisplay}
					</MantineCore.Alert>
				)}
			</MantineCore.Flex>
		</form>
	)
}

export default ResetPasswordForm
