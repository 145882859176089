import React, { ReactNode } from 'react'
import * as MantineCore from '@mantine/core'

interface GenericProps<T> {
	label?: ReactNode
	items: { display: string | ReactNode; value: T }[]
	value?: T
	nullable?: boolean
	onChange: (unit: T) => void
	size?: string
}

const RadioButtonGroup = <T extends string | number | boolean>({
	label,
	items,
	value,
	nullable = true,
	onChange,
	size = 'sm',
}: GenericProps<T | undefined>) => {
	return (
		<div className="toggle-filter-control">
			{label && (
				<div className="label">
					{label}
					<br />
				</div>
			)}
			<MantineCore.Button.Group>
				{items.map((option, index) => (
					<MantineCore.Button
						key={index}
						size={size}
						radius="sm"
						color={value === option.value ? 'yellow' : 'gray'}
						variant={value === option.value ? 'light' : 'default'}
						onClick={() =>
							onChange(
								// set value to new option, unless it's the same as last option in which case unset it - unless not nullable then just leave it
								value === option.value
									? nullable
										? undefined
										: value
									: option.value,
							)
						}
						style={{
							...{ color: value === option.value ? 'brown' : undefined },
						}}
					>
						<>{option.display}</>
					</MantineCore.Button>
				))}
			</MantineCore.Button.Group>
		</div>
	)
}

export default RadioButtonGroup
